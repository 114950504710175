import styled from '@emotion/styled';

export default styled.div`
	position: relative;

	.react-tooltip {
		border-radius: 0.5em;
		visibility: hidden;
		display: none;

		&.show {
			visibility: visible;
			display: inherit;
		}

		.tooltip-text {
			width: 100%;
			font-size: 1em;

			&.tooltip-text-large {
				font-size: 1.1em;
			}
		}
	}
`;
