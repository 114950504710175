import { ChangeEvent, ClipboardEvent, FunctionComponent, useState } from 'react';
import classnames from 'classnames';

import InputWrapper, { ValidationActions } from 'components/layout/forms/InputWrapper';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutFormsInput: FunctionComponent<Props> = ({ formik, name, label, type, placeholder, helper, value, onChange, style, styles, disabled, error, validationAction, decoratorLeft, decoratorRight, autoComplete, id }) => {
    const errorMessage = formik?.errors[name]?.toString() || error;
    const [isFocused, setIsFocused]: [boolean, Function] = useState(false);

    return (
        <StyledComponent
            className={classnames(
                'layout-forms-input',
                [
                    `style-${style}`,
                    `name-${name}`,
                ],
                {
                    'error': Boolean(error),
                    'decorator-left': Boolean(
                        error && validationAction === ValidationActions.Decorator ||
                        decoratorLeft && decoratorLeft.visible !== false
                    ),
                    'decorator-right': Boolean(decoratorRight && decoratorRight.visible !== false),
                }
            )}
            style={styles}
        >
            <InputWrapper
                name={name}
                label={label}
                helper={helper}
                error={errorMessage}
                validationAction={validationAction}
                decoratorLeft={decoratorLeft}
                decoratorRight={decoratorRight}
                disabled={disabled}
                isFocused={isFocused}
                value={value}
            >
                <input
                    className="input"
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    autoComplete={autoComplete}
                    id={id}
                    value={value === null ? '' : value}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        formik?.setFieldError(name, null);
                        onChange(event);
                    }}
                    onPaste={(event: ClipboardEvent<HTMLInputElement>): void => {
                        formik?.setFieldError(name, null);
                        onChange(event);
                    }}
                    disabled={disabled}
                />
            </InputWrapper>
            {errorMessage && errorMessage.trim() &&  (
                <div className="error-container">
                    <img
                        className="error-icon"
                        src="/images/icons/error.svg"
                        alt="error-icon"
                        loading="lazy"
                    />
                    <div
                        className="error-message"
                        dangerouslySetInnerHTML={{ __html: errorMessage }}
                    />
                </div>
            )}
        </StyledComponent>
    );
};

export default LayoutFormsInput;