import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: .5em;
    position: relative;

    .layout-forms-input-error {
        position: absolute;
        top: 100%;
    }

    .label-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .4em;

        .label-body {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .layout-forms-input-label {
                color: #000000;
                opacity: 0;
                transition: opacity .3s ease-in-out;
            }
        }

        .label-controls {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }


        .layout-forms-input-helper {
            margin-right: .5em;
        }
    }

    .internal-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: stretch;
        position: relative;
        border-radius: .5em;

        .input-decorator {
            position: absolute;
            top: 0;
            z-index: 2;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 1em;
            font-weight: 600;
            color: ${vars.colorText};

            &.input-decorator-left {
                left: 0;
                border-top-left-radius: .5em;
                border-bottom-left-radius: .5em;
            }

            &.input-decorator-right {
                right: 0;
                border-top-right-radius: .5em;
                border-bottom-right-radius: .5em;
            }

            &.input-decorator-state-info {
                font-size: .85em;
                color: #000000;
                text-decoration: underline;
                cursor: pointer;
            }
            &.input-decorator-state-success {
                background-color: ${vars.colorSuccess};
                color: ${vars.colorBackgroundContrast};
            }
            &.input-decorator-state-warning {
                background-color: ${vars.colorWarning};
                color: ${vars.colorBackgroundContrast};
            }
            &.input-decorator-state-alert {
                background-color: ${vars.colorAlert};
                color: ${vars.colorBackgroundContrast};
            }
        }
        .input-body {
            width: 100%;
        }
    }

    &.error {
        .internal-wrapper {
            .input-body {
                .input {
                    border: .1em solid #FF0000 !important;
                }
            }
        }
    }

    &.focus {
        .label-wrapper {
            .label-body {
                .layout-forms-input-label {
                    opacity: 1;
                }
            }
        }
    }
`;
