import { ReactNode } from 'react';
import { Effect, Offset, Place, TooltipProps, Type } from 'react-tooltip';

import Component from './component';

export interface Props {
    name: string;
    children?: ReactNode;
    text: ReactNode | string;
    place?: Place;
    type?: Type;
    effect?: Effect;
    offset?: Offset;
    reactTooltipProps?: TooltipProps;
}

Component.defaultProps = {
    children: null,
    place: 'right',
    type: 'dark',
    effect: 'solid',
    reactTooltipProps: {},
};

export default Component;