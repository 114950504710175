import { ChangeEvent } from 'react';
import { connect, FormikContextType } from 'formik';

import {
    InputDecorator,
    Props as InputWrapperProps,
    ValidationActions,
} from 'components/layout/forms/InputWrapper';

import Component from './component';


export interface Props extends InputWrapperProps {
    formik?: FormikContextType<any>;
    name: string;
    type?: string;
    placeholder?: string;
    value?: string | number;
    onChange: (event: ChangeEvent<any>) => any;
    style?: InputStyles;
    styles?: object;
    error?: string;
    disabled?: boolean;
    validationAction?: ValidationActions;
    decoratorLeft?: InputDecorator;
    decoratorRight?: InputDecorator;
    autoComplete?: string;
    id?:string;
}

export enum InputStyles {
    Primary = 'primary',
    Secondary = 'secondary',
}

Component.defaultProps = {
    type: 'text',
    placeholder: null,
    value: '',
    style: InputStyles.Primary,
    styles: {},
    error: null,
    disabled: false,
    validationAction: ValidationActions.Decorator,
    decoratorLeft: null,
    decoratorRight: null,
    autoComplete: 'none',
    id: null,
};

export default connect(Component);