import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 100%;

    input {
        width: 100%;
        height: 3.087em;
        border: none;
        padding: 0 1em;
        color: ${vars.colorText};
        border-radius: .4em;
        font-size: .9em;
        border: .0625em solid #000000;

        &::placeholder {
            color: ${vars.colorText};
        }

        &:disabled {
            background-color: #d7e2e4;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        &[type=number] {
            -moz-appearance: textfield;
        }
    }
    input::-webkit-textfield-decoration-container {
        visibility: hidden;
    }
    .error-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: .5em;

        .error-icon {
            margin-right: .5em;
            max-width: 100%;
        }
        .error-message {
            font-size: .8em;
            color: #FF0000;
        }
    }

    &.decorator-right {
        input {
            padding-right: 2.5em;
        }
    }

    &.style-secondary {
        .layout-forms-input-wrapper {
            margin: 0;

            .internal-wrapper {
                .input-body {
                    input {
                        height: 3em;
                        border: none;
                        border-radius: 0;
                        outline: none;

                        ::placeholder {
                            color: #000000;
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: ${vars.mobileM}) {
        font-size: 150%;
    }
`;
