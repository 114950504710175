import { FunctionComponent } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from 'components/layout/Tooltip';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutFormsInputHelper: FunctionComponent<Props> = ({ name, text }) => {
    return (
        <StyledComponent className="layout-forms-input-helper">
            <Tooltip
                name={name}
                text={text}
            >
                <div className="helper-icon">
                    <FontAwesomeIcon icon={faInfoCircle} />
                </div>
            </Tooltip>
        </StyledComponent>
    );
};

export default LayoutFormsInputHelper;