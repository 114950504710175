import Component from './component';

export interface Props {
    children: any;
}

Component.defaultProps = {
    children: null,
};

export default Component;