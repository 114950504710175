import { FunctionComponent, ReactNode } from 'react';
import { faCheckCircle, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { ColorStates } from 'types/states';

import InputHelper from 'components/layout/forms/InputHelper';
import InputLabel from 'components/layout/forms/InputLabel';
import Control from 'components/layout/ListDetailedElementControl';
import Spinner, { Colors, Positions, Sizes } from 'components/layout/Spinner';
import Tooltip from 'components/layout/Tooltip';

import { InputDecorator, Props, ValidationActions } from './index';
import StyledComponent from './styles';

const LayoutFormsInputWrapper: FunctionComponent<Props> = ({ name, children, label, helper, error, validationAction, decoratorLeft, decoratorRight, controls, disabled, isFocused, value }) => {
    const leftDecoratorError = error && validationAction === ValidationActions.Decorator && !decoratorLeft;
    const visibleControls = Array.isArray(controls)
        ? controls.filter(control => control.visible !== false)
        : [];

    const getRightDecorator = (): ReactNode => {
        if(decoratorRight && decoratorRight.visible !== false) {
            return (
                <div
                    className={classnames(
                        'input-decorator',
                        'input-decorator-right',
                        `input-decorator-state-${decoratorRight.state || ColorStates.Info}`
                    )}
                >
                    {decoratorRight.loading
                        ? (
                            <Spinner
                                size={Sizes.Small}
                                position={Positions.Relative}
                                color={Colors.White}
                            />
                        )
                        : getIcon(decoratorRight)}
                </div>
            );
        }

        return null;
    };

    const getIcon = (decorator: InputDecorator): ReactNode => {
        if(decorator.children) {
            return decorator.children;
        }

        if(decorator.state) {
            switch (decorator.state) {
                case ColorStates.Success:
                    return (
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="check-icon"
                        />
                    );
                case ColorStates.Warning:
                    return (
                        <Tooltip
                            name={name}
                            text={error}
                        >
                            <FontAwesomeIcon icon={faExclamationCircle} />
                        </Tooltip>
                    );
                case ColorStates.Alert:
                    return (
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="times-icon"
                        />
                    );
            }
        }

        return null;
    };

    return (
        <StyledComponent
            className={classnames(
                'layout-forms-input-wrapper',
                {
                    error: Boolean(error),
                    focus: isFocused || Boolean(value),
                }
            )}
        >
            {(label || helper) && (
                <div className="label-wrapper">
                    <div className="label-body">
                        {helper && (
                            <InputHelper
                                name={name}
                                text={helper}
                            />
                        )}
                        {label && (
                            <InputLabel>
                                {label}
                            </InputLabel>
                        )}
                    </div>
                    {Array.isArray(visibleControls) && (
                        <div className="label-controls">
                            {visibleControls.map(control => (
                                <Control
                                    key={control.key}
                                    {...control}
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}
            <div className="internal-wrapper">
                <div
                    className={classnames(
                        'input-body',
                        { 'left-decorator-error': leftDecoratorError }
                    )}
                >
                    {children}
                </div>
                {(!disabled) && getRightDecorator()}
            </div>
        </StyledComponent>
    );
};

export default LayoutFormsInputWrapper;