import { FunctionComponent, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

import { Props } from './index';
import StyledComponent from './styles';

const ReactTooltip = dynamic(() => import('react-tooltip'), {
    ssr: false,
});

const LayoutTooltip: FunctionComponent<Props> = ({ name, text, reactTooltipProps, effect, type, children }) => {
    const [isMounted, setMount] = useState(false);

    useEffect(() => {
        setMount(true);
    }, []);

    if(!isMounted) return null;
    else return (
        <StyledComponent
            className="layout-tooltip"
        >
            <div
                data-tip
                data-for={name}
                className="tooltip-container"
            >
                {children}
            </div>
            <ReactTooltip
                className="react-tooltip"
                id={name}
                type={type}
                effect={effect}
                {...reactTooltipProps}
            >
                <div className="tooltip-text">
                    {text}
                </div>
            </ReactTooltip>
        </StyledComponent>
    );
};

export default LayoutTooltip;